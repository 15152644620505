import React, { useEffect, useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import Card from 'react-bootstrap/Card';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import remarkGfm from 'remark-gfm';

const Rules = () => {
	const [RuleListData, setRuleListData] = useState(null);
	const [RuleData, setRuleData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(()=>{
		const fetchData = async()=>{
			setLoading(true);
			try {
				const apiserverresponse = await axios.get(`https://api.htlab.kr/rule?date=${searchParams.get("date")}`);
				setRuleListData(apiserverresponse.data["names"]);
				const githubserverresponse = await axios.get(apiserverresponse.data["github_link"]);
				setRuleData(githubserverresponse.data);
				setLoading(false);
			}
			catch(e) {
				console.log(e);
				setLoading(false);
				setError(true);
			}
		};
		fetchData();
	},[searchParams])

	return (
		<>
			<Helmet>
				<title>디스코드 이용규칙 - 삼해트의 공방</title>
				<meta property="og:title" content="디스코드 이용규칙 - 삼해트의 공방" />
			</Helmet>

			<br />
			<div
				style={{
					display: 'flex',
					width: '100%',
					justifyContent: 'center',
				}}
			>
				<Card bg="dark" className='mb-2' style={{width: "80%"}}>
					<Card.Header>삼해트의 공방</Card.Header>
					<Card.Body>
						<Card.Title><h1>디스코드 이용규칙</h1></Card.Title>
						<Card.Text>
							삼해트의 공방에서 활동하기 전에 필수로 읽어봐야 할 이용규칙이에요!<br />
							이 이용규칙을 꼭 준수하면서 서버에서 활동하시길 바랄게요! ❤
						</Card.Text>
					</Card.Body>
				</Card>
			</div>

			<br />

			<DropdownButton id="rule_dropdown" title="이전 이용 규칙 확인하기" onSelect={(e)=>{searchParams.set('date', e);setSearchParams(searchParams)}}>
				{
					loading
					? <Dropdown.Item disabled>로딩중입니다...</Dropdown.Item>
					: (
						error || !RuleListData
						? <Dropdown.Item disabled>오류가 발생했습니다.</Dropdown.Item>
						: RuleListData.map(rule =>(
							<Dropdown.Item eventKey={rule.date}>{rule.name}</Dropdown.Item>
						))
					)
				}
			</DropdownButton>

			<br />
				
			<div
				style={{
					display: 'flex',
					width: '100%',
					justifyContent: 'center',
				}}
			>
				<Card bg="dark" className='mb-2' style={{width: "80%"}}>
					<Card.Body>
					{
						loading
						? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh'}}><h1>로딩중입니다...</h1></div>
						: (
							error || !RuleData
							? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh'}}><h1>오류가 발생했습니다.</h1></div>
							: <div
								style={{
									display: 'inline-block',
									marginTop: '10px',
									textAlign: 'left',
									justifyContent: 'center',
									wordBreak: 'break-all',
								}}
							>
								<ReactMarkdown
									remarkPlugins={[remarkGfm]}
									rehypePlugins={[rehypeRaw]}
									components={{blockquote({ node, children, ...props }) {
										return (<blockquote style={{padding: '0 1.8em', color: '#c4c4c4', borderLeft: '0.2em solid #d0d7de'}} {...props}>{children}</blockquote>);
										},
										a({ node, children, ...props }) {
											return (<a {...props} target="_blank">{children}</a>)
										}
									}}
								>
									{RuleData}
								</ReactMarkdown>
							</div>
						)
					}
					</Card.Body>
				</Card>
			</div>
		</>
	);
};

export default Rules;