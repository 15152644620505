import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Main = () => {
	return (
		<>
			<Helmet>
				<title>메인 - 삼해트의 공방</title>
				<meta property="og:title" content="메인 - 삼해트의 공방" />
			</Helmet>

			<br />
			<img src="/images/favicon.ico" alt="삼해트의 공방 로고" className="rounded-circle" /><br /><br />
			<h1>삼해트의 공방</h1>
			<p>교단이, 해피트리봇을 개발 및 운영합니다.</p>
			<Link to="/service">운영중인 서비스 확인하기!</Link>
		</>
	);
};

export default Main;