import React from "react";
import { hydrate } from "react-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<React.StrictMode><HelmetProvider><App /></HelmetProvider></React.StrictMode>, rootElement);
} else {
  ReactDOM.createRoot(rootElement).render(<React.StrictMode><HelmetProvider><App /></HelmetProvider></React.StrictMode>);
}

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          if (window.confirm("새로운 콘텐츠가 있습니다. 새로고침 하시겠습니까?")) {
            window.location.reload();
          }
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  }
});

reportWebVitals();