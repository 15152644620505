import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Image from 'react-bootstrap/Image';
import { Helmet } from 'react-helmet-async';
import Card from 'react-bootstrap/Card';

const Members = () => {
	const [members, setMembers] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	useEffect(()=>{
		const fetchData = async()=>{
			setLoading(true);
			try{
				const response = await axios.get('https://api.htlab.kr/members');
				setMembers(response.data.members);
			}
			catch(e){
				console.log(e);
				setError(true);
			}
			setLoading(false)
		};
		fetchData();
	},[])

	return (
		<>
			<Helmet>
				<title>멤버 소개 - 삼해트의 공방</title>
				<meta property="og:title" content="멤버 - 삼해트의 공방" />
			</Helmet>

			<br />
			<div
				style={{
					display: 'flex',
					width: '100%',
					justifyContent: 'center',
				}}
			>
				<Card bg="dark" className='mb-2' style={{width: "80%"}}>
					<Card.Header>삼해트의 공방</Card.Header>
					<Card.Body>
						<Card.Title><h1>멤버 소개</h1></Card.Title>
						<Card.Text>
							삼해트의 공방에서 열정적으로 일하시는 멤버분들이에요!<br />
							만나게 된다면 친절하게 맞이해주세요! 😄
						</Card.Text>
					</Card.Body>
				</Card>
			</div>

			{
				loading
				? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh'}}><h1>로딩중입니다...</h1></div>
				: (
					error
					? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh'}}><h1>오류가 발생했습니다.</h1></div>
					: (
						!members
						? null
						: <div
							style={{
								display: 'inline-block',
								marginTop: '10px',
								textAlign: 'center',
								justifyContent: 'center',
								wordBreak: 'break-all',
							}}
						>
							{members.map(member =>(
								<>
									<Card bg="dark" style={{ width: '18rem', display: 'flex' }}>
										<Card.Header key={member.name}>{member.position}</Card.Header>
										<Image src={member.avatar} alt={member.name}  /> 
										<Card.Body>
											<Card.Title>{member.name}</Card.Title>
											<Card.Text>{member.description}</Card.Text>
										</Card.Body>
									</Card>
									<br />
								</>
							))}
						</div>
					)
				)
			}
		</>
	);
};

export default Members;