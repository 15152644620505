import logo from '../images/favicon.png';
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import { FileEarmarkText, PersonBadgeFill, Discord } from 'react-bootstrap-icons';
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom';

const Header = () => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar variant="dark" bg="dark" expand="lg" fixed="top" expanded={expanded}>
            <Container fluid>
                <LinkContainer to="/" onClick={() => setExpanded(false)}>
                    <Navbar.Brand><Image src={logo} alt="profile" width="28" height="28" roundedCircle /> 삼해트의 공방</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="navbar-dark" onClick={() => setExpanded(expanded ? false : "expanded")} />

                <Navbar.Collapse id="navbar-dark">
                {/*<Nav>
                    <NavDropdown
                        id="nav-dropdown-dark"
                        title={<span><FileEarmarkText /> 약관</span>}
                        menuVariant="dark"
                    >
                        <NavDropdown.Item style={{textAlign: 'center'}} as={Link} to='/rule' onClick={() => setExpanded(false)}>디스코드 이용규칙</NavDropdown.Item>
                        <NavDropdown.Item style={{textAlign: 'center'}} disabled as={Link} to='/terms' onClick={() => setExpanded(false)}>서비스 이용약관</NavDropdown.Item>
                        <NavDropdown.Item style={{textAlign: 'center'}} disabled as={Link} to='/privacy' onClick={() => setExpanded(false)}>개인정보 처리방침</NavDropdown.Item>
                    </NavDropdown>
                </Nav>*/}
                <Nav>
                    <Nav.Link as={Link} to='/rule' onClick={() => setExpanded(false)}><FileEarmarkText /> 디스코드 규칙</Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link as={Link} to='/members' onClick={() => setExpanded(false)}><PersonBadgeFill /> 멤버</Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link as={Link} to='/discord' onClick={() => setExpanded(false)}><Discord /> 디스코드</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;