import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollTop from "./components/Scroll";
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './pages/Main';
import Members from './pages/Members';
import Rule from './pages/Rule';
import Discord from './pages/Discord';
import NotFound from './pages/NotFound';

function App() {
  return (
		<div className='App'>
			<BrowserRouter>
				<ScrollTop />
				<Header />
				<Routes>
					<Route path="/" element={<Main />}></Route>
					<Route path="/members" element={<Members />}></Route>
					<Route path="/discord" element={<Discord />}></Route>
					<Route path="/rule" element={<Rule />}></Route>
					<Route path="/404" element={<NotFound />}></Route>
					<Route path="*" element={<NotFound />}></Route>
				</Routes>
				<Footer />
			</BrowserRouter>
		</div>
  );
}

export default App;
