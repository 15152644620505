import React from 'react';
import Button from 'react-bootstrap/Button';
import { ChevronLeft, HouseFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

const NotFound = () => {
	const history = useNavigate();

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '90vh'
			}}
		>
			<Helmet>
				<title>404 - 삼해트의 공방</title>
				<meta property="og:title" content="404 - 삼해트의 공방" />
			</Helmet>
			<div>
				<h1>🚧 404 🚧</h1>
				<h2>요청하신 페이지가 존재하지 않습니다.</h2>
				<p>이 페이지가 이동되었거나, 존재하지 않는 것 같아요.</p>
				<Button as={Link} onClick={()=>{history(-1)}} variant="info"><ChevronLeft /> 이전 페이지로 이동하기</Button> <Button as={Link} to="/" variant="primary"><HouseFill /> 메인 페이지로 이동하기</Button>
			</div>
		</div>
	);
};

export default NotFound;