import logo from '../images/favicon.png';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="py-3 my-4">
            <hr />
            <h3><Image src={logo} alt="삼해트의 공방 로고" width="34px" height="34px" roundedCircle /> 삼해트의 공방</h3>
            <span>교단이, 해피트리봇을 개발 및 운영합니다.</span><br /><br />
            <Link to="/members" className="footer_atag">공방 멤버</Link > | <Link to="/service" className="footer_atag">공방 서비스 목록</Link> | <Link to="/discord" className="footer_atag">공방 디스코드</Link>
            <br /><br />
            <p className="text-center text-muted">© 2021-2023 삼해트의 공방. All rights reserved.</p>
        </footer>
    );
}

export default Footer;