import React from 'react';
import { Helmet } from 'react-helmet-async';

const Discord = () => {
    if (navigator.userAgent !== "ReactSnap") {
        window.location.replace('https://discord.gg/TD9BvMxhP6');
    }
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '90vh'
			}}
		>
			<Helmet>
				<title>디스코드 - 삼해트의 공방</title>
				<meta property="og:title" content="디스코드 - 삼해트의 공방" />
			</Helmet>

            <div>
                <h1>📡 원하시는 페이지로 이동할게요!</h1>
                <p>⚠ 만일 이동되지 않는다면, <a href="https://discord.gg/TD9BvMxhP6">여기</a>를 눌러 이동해주세요!</p>
            </div>
		</div>
	);
};

export default Discord;